import * as _ from '@technically/lodash'

import { loadRecipe, setOriginValues } from '~p/client/common/actions'
import configureRouter from '~p/client/configureRouter'
import configureStore from '~p/client/configureStore'

import * as meta from '../common/meta'
import { PROP_DEF_DICT, PROP_DEFS } from '../common/sheets'
import controlTree from './controlTree'
import updater from './updater'

const store = configureStore({
  updater,
  initialState: {
    sheets: { propDefs: PROP_DEF_DICT },
    sheetLists: { propDefs: PROP_DEFS },
  },
  controlTree,
})

const routes = ['/sku/:sku', '/design/:recipeId(/:status)']

const { initialMatches } = configureRouter(controlTree, store, routes)

if (initialMatches) {
  const matches = initialMatches
  const { sku, recipeId } = matches

  if (recipeId) {
    store.dispatch(loadRecipe(controlTree, recipeId))
  } else if (sku) {
    const { model, size } = meta.getSkuValues(sku)
    const sportId = _.findKey(model.subset.sportId, _.identity)
    const ctValues = {
      'filter.sport': sportId,
      'helmet.model': model.id,
      'helmet.size': size.id,
    }
    store.dispatch(controlTree.setValues(ctValues))
    store.dispatch(setOriginValues(controlTree))
  }
} else {
  store.dispatch(setOriginValues(controlTree))
}

export default store
