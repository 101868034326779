import React from 'react'

import Spinner from './Spinner'

import './Renderer.css'

const Renderer = ({ children, isLoading = false }) => (
  <div>
    {isLoading && (
      <div className="renderer-loading">
        <div className="renderer-spinner">
          <Spinner />
        </div>
      </div>
    )}
    <div className="renderer-inner">{children}</div>
  </div>
)

export default Renderer
