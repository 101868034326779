import React from 'react'
import { Provider } from 'react-redux'
import WebFont from 'webfontloader'

import Layout from '~rawlings/client/components/Layout'

import '~rawlings/client/components/Main.css'

import Render3d from '../../render3d/Render3d'

WebFont.load({
  google: {
    families: ['Open Sans:300,400,600,700'],
  },
})

const getProductInfo = (nodes, recipeId) => ({
  summarySections: [],
  title: nodes['calc.sku'].value,
  priceWithCurrency: nodes['calc.priceWithCurrency'].value,
  configurationId: recipeId,
})

const Root = ({ store }) => (
  <Provider store={store}>
    <Layout Renderer={<Render3d />} getProductInfo={getProductInfo} />
  </Provider>
)

export default Root
