import React from 'react'

import { cn } from '~p/client/components/utils'

import './Icon.css'

function Icon({ name }) {
  return (
    <svg className={cn(['icon', `mod-${name}`])}>
      <use xlinkHref={`#rw-icon-${name}`} />
    </svg>
  )
}

export default Icon
