import React from 'react'

import { t } from '~p/i18n'
import { cn, states } from '~p/client/components/utils'

import Product from './Product'
import Button from './Button'
import Icon from './Icon'

import './Purchase.css'

const Purchase = ({
  isCountDownVisible,
  isActive,
  product,
  onCart,
  onSummary,
}) => (
  <div
    className={cn([
      'purchase',
      states(isActive ? ['on'] : []),
      isCountDownVisible ? 'isCountDownVisible' : '',
    ])}
  >
    <div className="purchase-product mod-infoCard">
      <Product {...product}>
        <Button classMods={['hasIcon']} onClick={onCart}>
          <Icon name="cart" />{' '}
          {window.serverConfig.orderButtonText || t('_rawlings:addToCart')}
        </Button>
        <a className="purchase-summaryLink" onClick={onSummary}>
          <Icon name="info" /> {t('_rawlings:summary')}
        </a>
      </Product>
    </div>
  </div>
)

export default Purchase
