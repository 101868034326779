import React from 'react'
import * as _ from '@technically/lodash'

import getAsset from '~p/getAsset'
import { cn, mods } from '~p/client/components/utils'

import Icon from './Icon'

import './Tile.css'

function Tile({
  isSelected,
  isDisabled,
  onClick,
  tileType,
  background,
  dashed,
  text,
  imageSrc,
  secondaryImageSrc,
  customColor,
  stripeColor,
  glossy,
  title,
}) {
  const tileMods = [
    mods([tileType]),
    isSelected ? mods(['selected']) : [],
    isDisabled ? mods(['disabled']) : [],
  ]

  const tileContentMods = mods([background])

  const tileContentStyle = {
    backgroundImage: imageSrc && !dashed ? `url(${imageSrc})` : null,
    backgroundColor: customColor,
  }

  return (
    <div
      className={cn(['tile', tileMods])}
      onClick={isDisabled ? null : onClick}
      title={title}
    >
      <div
        className={cn(['tile-content', tileContentMods])}
        style={tileContentStyle}
      >
        {secondaryImageSrc && (
          <img src={secondaryImageSrc} alt="" className="tile-secondaryImage" />
        )}
        {glossy && (
          <img src={getAsset('glossy.svg')} alt="" className="tile-glossy" />
        )}
        {dashed && (
          <svg className="tile-dashed">
            <use
              xlinkHref={
                _.startsWith(tileType, 'wide') ? '#rw-dashed-wide' : (
                  '#rw-dashed'
                )
              }
            />
          </svg>
        )}
        {stripeColor && (
          <div
            className="tile-stripe"
            style={{ backgroundColor: stripeColor }}
          />
        )}
        {text && <div className="tile-text">{text}</div>}
      </div>
      {isSelected && (
        <div className="tile-select">
          <Icon name="checkmark" />
        </div>
      )}
    </div>
  )
}

Tile.defaultProps = {
  background: 'midGray',
  dashed: false,
  isSelected: false,
  text: null,
  imageSrc: null,
  secondaryImageSrc: null,
  customColor: null,
  glossy: false,
  isDisabled: false,
}

export default Tile
