import { connect } from 'react-redux'

import { setPreviewGenerator } from '~p/client/common/actions'
import { setFocusMode } from '~rawlings/client/actions'

import controlTree from '../client/controlTree'
import { CAMERA_DEF_DICT } from '../common/sheets'

import { Render3dBase } from './Render3dBase'

const mapStateToProps = (state) => {
  const { isFocusMode } = state
  const expandedRecipe = controlTree.getExpandedRecipeNested(state)

  const activeView = isFocusMode ? 'overview' : state.render3d.activeView

  let cameraDef = CAMERA_DEF_DICT[activeView] || CAMERA_DEF_DICT.overview
  if (state.layoutMode === 'desktop') {
    cameraDef = {
      ...cameraDef,
      radius: cameraDef && cameraDef.desktopRadius,
    }
  }

  return { expandedRecipe, cameraDef, isFocusMode }
}

const mapDispatchToProps = {
  setFocusMode,
  setPreviewGenerator,
}

const Render3d = connect(mapStateToProps, mapDispatchToProps)(Render3dBase)

export default Render3d
